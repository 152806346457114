<template>
  <div class="settings-file-preview">
    <!--  Header    -->
    <LocalBreadcrumb
      :isPageNumber="false"
      :items="breadcrumbItems"
      @setCurrentPage="toPage"
    />
    <!-- Actions  -->
    <div class="mb-2 d-flex justify-content-start">
      <ClNavButton :label="$t('buttons.goBack')" @handler="goBack" class="mr-2"/>
      <!--  ADD    -->
      <ClResponsiveButton :label="$t('buttons.add')" @handler="addIcon"/>
    </div>
    <!-- Content   -->
    <CRow class="mr-0">
      <CCol col="12">
        <CCard class="zq--wizard-card">
          <CCardHeader>
            <div class="d-flex justify-content-between">
              <strong class="title">{{ texts.previewFile.title }}</strong>
              <CLink
                class="card-header-action btn-minimize"
                @click="basicIsCollapsed = !basicIsCollapsed"
              >
                <ClCardArrow :is-collapsed="basicIsCollapsed"/>
              </CLink>
            </div>
          </CCardHeader>
          <CCollapse :show="basicIsCollapsed" :duration="400" v-if="previewFileObject && fileString">
            <CCardBody class="banner">
              <img :src="fileString"  alt="file" />
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {startCase} from 'lodash';
import LocalBreadcrumb from '@/shared/components/LocalBreadcrumb';
import {dateFormate} from '@/utils/dateFormate';
import {pageConfig} from '@/config';
import ClNavButton from "@/shared/components/formComponents/ClNavButton";
import ClResponsiveButton from "@/shared/components/formComponents/ClResponsiveButton";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";

export default {
  components: {
    LocalBreadcrumb,
    ClNavButton,
    ClResponsiveButton,
    ClCardArrow,
  },
  props: {
    fileData: Object,
    backPath: String,
    backPathOrigin: String,
    breadcrumbItems: Array,
  },
  data() {
    return {
      basicIsCollapsed: true,
      sortBy: pageConfig.sortBy,
      texts: {
        ...achievementsTexts
      },
      previewFileObject: null,
      fileString: null,
    }
  },
  computed: {
    ...mapGetters('files', [
      'loading',
      'success',
      'message'
    ]),
    originPath() {
      return startCase(this.backPathOrigin);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async initialize() {
      const file = await this.handleGetFileObjects_item({idArray: [this.fileData.id]});
      this.previewFileObject = file[0];
      this.fileString = this.previewFileObject.uri;
    },
    goBack() {
      const path = this.fileData.from ? this.fileData.from.toLowerCase() : this.backPath;
      this.$emit('setCurrentPage', {
        page: path,
        data: {
          id: this.fileData.repositoryId
        }
      });
    },
    toPage(e) {
      const path = e;
      this.$emit('setCurrentPage', {
        page: path,
        data: path === this.backPath ? {
          id: this.fileData.repositoryId
        } : {}
      });
    },
    addIcon() {
      this.$emit('addIcon', {id: this.fileData.id, uri: this.fileString});
    },
    dateFormate(val) {
      return dateFormate(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-file-preview {
  .settings-bread-content {
    width: 100%;
    font-size: 1.53125rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
  }

  .settings-bread {
    cursor: pointer;
    color: #321fdb;

    &:hover {
      text-decoration: underline;
    }
  }

  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }

  .overview-label {
    font-style: italic;
    color: darkgray;
  }

  .files-metadata--list {
    margin: 0;
    padding: 0;
    font-weight: bold;
  }

  .zq--wizard-card {
    width: fit-content;
  }

  .banner {
    & > img {
      display: block;
      max-width: 100%;
      max-height: 250px;
    }
  }
}
</style>